import { combineReducers } from "redux"

import landingpage from "./landingpage"
import impressum from "./impressum"
import news from "./news"
import presse from "./presse"

export default combineReducers({
    landingpage,
    impressum,
    news,
    presse,
})
