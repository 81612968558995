import fetch from "cross-fetch"

import { backendUrl } from "../../config"
import { reportError } from "../../components/reuse/util/eventReporter"

export const FETCHING_PRESSE = "FETCHING_PRESSE"
export const RECEIVED_PRESSE = "RECEIVED_PRESSE"

export const fetchPresse = () => {
    return dispatch => {
        dispatch({ type: FETCHING_PRESSE })
        return fetch(backendUrl + "/presse")
            .then(res => {
                if (res.status !== 200) {
                    reportError(FETCHING_PRESSE, `${res.status}`)
                    throw new Error("Fehler beim Laden des Pressebereichs")
                }
                return res.json()
            })
            .then(data => dispatch(receivedPresse(data)))
            .catch(err => dispatch(receivedPresse(undefined, err)))
    }
}

const receivedPresse = (data, error) => {
    return {
        type: RECEIVED_PRESSE,
        result: {
            data: data,
            error: error,
        },
    }
}
